import React from "react"
import Marquee from "react-fast-marquee";

const Footer = () => {
    return (
        <div>
            <Marquee speed={200} loop={0}>
                <h2 className="text-xl sm:text-2xl md:text-3xl py-2 pl-2 font-saira font-bold text-black flex-shrink-0">
                    $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE $DANCE
                </h2>
            </Marquee>
        </div>
    )
};

export default Footer;
