import React from "react"
import dogDanceMeme from '../assets/dog-dance-meme.gif'
import { AiOutlineTwitter } from 'react-icons/ai'
import { FaTelegramPlane } from "react-icons/fa";
import SolanaLogo from "./Solana";
import DexScreenLogo from '../assets/dex-screener/dex-screener.png'
import PumpFunLogo from '../assets/Pumpfun.png'


const ca = '8x2MjLfQD4s4hrjNTLju4JURrmSqeujTABdcJX9hkDzc'
const pumpFun = ''
const dexScreener = 'https://dexscreener.com/solana/8x2mjlfqd4s4hrjntlju4jurrmsqeujtabdcjx9hkdzc'
const twitterUrl = 'https://x.com/DancingDogCoin_'
const telegramUrl = 'https://t.me/d4ncingdogp0rtal'
const videoUrl = 'https://freight.cargo.site/i/Z1898158523813072706774745023123/teste.mp4'

const Body = () => {
    return (
        <div className="flex flex-col justify-center w-full h-full pt-6 flex-grow">
            <div className="mx-auto flex flex-col md:flex-row justify-center items-center">

                <div className="flex flex-col w-full h-fit justify-start items-center font-bold">
                    <div className="text-3xl mt-[10%] lg:mt-0 sm:text-3xl md:text-4xl lg:text-4xl xl:text-5xl 2xl:text-7xl text-[#26221c] font-roboto w-full text-center md:text-left pl-[5%]">
                        <p className="font-bungee text-shadow-md ">JUST THE FAMOUS</p>
                        <p className="font-bungee text-shadow-md ">BRAZILLIAN DANCING DOG!</p>
                        <div className="flex flex-col md:flex-row justify-center md:justify-start items-center pt-4">
                            <p>NOW ON</p>
                            <SolanaLogo />
                        </div>
                    </div>
                    <div className="flex flex-row md:flex-row justify-center items-center pt-4">
                        <div className="flex pt-4 items-center justify-center">
                            <a href={pumpFun} rel="noopener noreferrer" target="_blank" className="hidden 'px-4">
                                <img src={PumpFunLogo} className="size-[50px] sm:size-[50px] md:size-[62px] xl:size-[68px] 2xl:size-[80px] mb-4 hover:scale-105" />
                            </a>
                            <a href={dexScreener} rel="noopener noreferrer" target="_blank" className="px-4">
                                <img src={DexScreenLogo} className="size-[50px] sm:size-[50px] md:size-[62px] xl:size-[68px] 2xl:size-[80px] mb-4 hover:scale-105" />
                            </a>
                            <a href={twitterUrl} rel="noopener noreferrer" target="_blank" className="px-4">
                                <button>
                                    <AiOutlineTwitter className="size-16 sm:size-18 md:size-20 lg:size-22 xl:size-20 2xl:size-26 hover:scale-105 fill-[#1c1c1c]" />

                                </button>
                            </a>
                            <a href={telegramUrl} rel="noopener noreferrer" target="_blank" className="px-4">
                                <button>
                                    <FaTelegramPlane className="size-16 sm:size-18 md:size-20 lg:size-22 xl:size-20 2xl:size-26 hover:scale-105 fill-[#1c1c1c]" />
                                </button>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center items-center w-full pt-6">
                    <video controls autoPlay muted className="max-w-[300px] md:max-w-[360px] lg:max-w-[420px] xl:max-w-[540px]">
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                </div>

            </div>

            <p className="font-sans text-black font-bold px-4 text-xs sm:text-sm md:text-base lg:text-lg">ca: {ca}</p>
        </div>

    )
};

export default Body;
