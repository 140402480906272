import React from "react"
import DanceingDogTitle from '../assets/DANCING_DOG.png'
import Logo from '../assets/DogLogoGif.gif'
import CoinFlip from '../assets/COIN_FLIP.gif'

const Header = () => {
    return (
        <div className="flex flex-row justify-center items-center mt-[1%] flex-shrink-0">
            <div className="flex justify-center items-center top-0 right-0 left-0 w-fit h-fit max-w-[2040px] mx-auto mt-[-3%] lg:mt-[-2%] xl:mt-[-1%] 2xl:mt-0">
                <img src={Logo} className="hidden xl:block sm:max-h-[150px] md:max-h-[200px] xl:max-h-[240px] 2xl:max-h-[280px]" />
                <img src={DanceingDogTitle} className="px-2 pt-2 sm:pl-6" />
                <img src={CoinFlip} className="hidden lg:block max-w-[175px]" />
            </div>
        </div>
    )
};

export default Header;
