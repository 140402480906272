import React from "react"
import SolanaLogoSvg from '../assets/solana/solana_black.svg'

const SolanaLogo = () => {
    return (
        <img src={SolanaLogoSvg} className="px-4 max-h-[44px] sm:max-h-[44px] md:max-h-[48px] xl:max-h-[56px] 2xl:max-h-[70px]" />
    )
};

export default SolanaLogo;
